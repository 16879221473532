.LabelText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--matterhorn);
}

.LabelDescriptionText {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--matterhorn);
}
