.SelectionBreadCrumb {
  display: flex;
  align-items: center;
  background: none;
  // margin-bottom: 8px;
  max-width: 100%;

  svg {
    transform: rotate(270deg);
    flex-shrink: 0;
  }

  span {
    margin-left: 4px;
    padding: 4px 12px;
    border-radius: 1000px;
    background-color: var(--light-teal);
    color: var(--refined-teal);
  }
}

.MobileContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.Hide {
    flex: 0;
  }
}

.MobileButtonContainer {
  width: 100%;

  button {
    width: 100%;
    min-height: 50px !important;
    font-size: 16px !important;
  }
}

.CardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  .CardHeaderTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      margin-right: 8px;
    }
  }
}

.MobileBackContainer {
  margin-bottom: 24px;
}
