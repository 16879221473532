.PageTitle {
  width: 100%;

  .PageTitleTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.Column {
      flex-direction: column;
      align-items: start;

      .Controls {
        gap: 16px;
        margin-top: 16px;
        width: 100%;
        overflow-x: auto;

        button {
          flex: 1;
          margin-left: 0;
        }
      }
    }

    .Container {
      width: 100%;
      align-items: center;
      display: flex;
      min-width: 0;

      @media screen and (max-width: 932px) {
        flex-direction: column;
        align-items: start;
      }
    }

    &.Lg {
      margin-bottom: 24px;
    }

    &.Sm {
      margin-bottom: 16px;

      @media screen and (max-width: 932px) {
        margin-bottom: 24px;
      }
    }

    &.Border {
      border-bottom: 1px solid var(--lines);
      padding-bottom: 5px;

      @media screen and (max-width: 932px) {
        padding-bottom: 24px;
      }
    }

    .Title {
      font-size: 28px;
      line-height: normal;
      letter-spacing: -1px;
      max-width: 100%;
      color: var(--matte-black);

      .Highlight {
        color: var(--refined-teal);
      }

      span {
        color: var(--refined-teal);
      }

      @media screen and (max-width: 932px) {
        width: 100%;
        letter-spacing: -2px;
      }

      &.Lg {
        font-weight: 700;
      }

      &.Sm {
        font-weight: 600;
      }
    }

    .Controls {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      button {
        margin-left: 10px;
      }
    }
  }

  .PageTitleMiddle {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--cool-grey);
  }

  .PageTitleBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 932px) {
      gap: 8px;
    }

    &.Lg {
      margin-bottom: 24px;
    }

    &.Sm {
      margin-bottom: 16px;
    }

    .ListFilter {
      display: flex;
    }
  }
}

.SearchContainer {
  width: 444px;
}

.SearchIcon {
  color: var(--dark-grey);
}
